import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuButtonClick = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div>
      <header
        id='header'
        className={`navbar-demo ${menuOpen ? 'open-nav' : ''} `}
        style={{ position: menuOpen ? 'fixed' : 'absolute' }}
      >
        <div className='nav-bar'>
          <ul>
            <li
              style={{
                fontFamily: 'deluce',
                color: 'white !important',
              }}
            >
              <Link to='/'>Home</Link>
              <Link to='/'>Gallery</Link>
              <Link to='/'>About Us</Link>
              <Link to='/'>Contact Us</Link>
            </li>
          </ul>
        </div>
        <button type='' className='menu-btn' onClick={handleMenuButtonClick}>
          <i
            className='fa fa-bars'
            aria-hidden='true'
            style={{ color: 'black' }}
          ></i>
        </button>
      </header>
    </div>
  );
};

export default Header;
