import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

import meAndKei_1 from '../assets/images/me-and-kei-in-la.jpg';
import meAndKei_2 from '../assets/images/me-and-kei-in-miami.jpg';
import meAndKei_3 from '../assets/images/me-and-kei-in-ny.jpg';
import meAndKei_4 from '../assets/images/me-and-kei-on-a-boat.png';

import keiOnBoat from '../assets/images/kei-on-a-boat.jpg';
import keiInMexico from '../assets/images/kei-in-mexico.png';
import keiInNY from '../assets/images/kei-in-ny.jpg';

import justMe from '../assets/images/just-me.jpg';
import justKei from '../assets/images/just-kei.jpg';

import { Parallax, Background } from 'react-parallax';

const HomeScreen = () => {
  const date1 = new Date();
  const date2 = new Date('10/01/2022');

  // To calculate the time difference of two dates
  const Difference_In_Time = date2.getTime() - date1.getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return (
    <div>
      <Header />
      <div className='hero-image'>
        <div className='about-me'>
          <div>
            {/* <label className='welcome_label'>Brendan &amp; Keishawwna</label> */}
            <label className='welcome_label'>We're Getting Married</label>
            <h2>Brendan &amp; Keishawnna</h2>
            <h4>
              October 1st 2022 <br /> Los Angeles California
            </h4>
            <h5>{Difference_In_Days.toFixed()} days to go!</h5>
          </div>
        </div>
      </div>
      <div className='row' style={{ padding: '25px' }}>
        <div className='col-lg-4 col-md-12 mb-4 mb-lg-0'>
          <img src={keiOnBoat} className='w-100 shadow-1-strong mb-4' />
          <img src={keiInNY} className='w-100 shadow-1-strong mb-4' />
        </div>

        <div className='col-lg-4 mb-4 mb-lg-0'>
          <img src={meAndKei_4} className='w-100 shadow-1-strong mb-4' />
          <img src={justMe} className='w-100 shadow-1-strong mb-4' />
        </div>

        <div className='col-lg-4 mb-4 mb-lg-0'>
          <img src={meAndKei_2} className='w-100 shadow-1-strong mb-4' />
          <img src={justKei} className='w-100 shadow-1-strong mb-4' />
        </div>
      </div>

      <div className='row' style={{ display: 'none' }}>
        <center>
          <section
            id='registry'
            //   style='background: url("img/bg/registry.jpg") left top no-repeat;'
          >
            <div class='wrapper'>
              <h2 class='ir heading-registry'>registry</h2>
              <div className='col-md-8'>
                <p>
                  What we want most for our wedding is to have all of our
                  friends and family there to celebrate the occasion with us,
                  and so, more than anything we would simply enjoy your
                  presence. (We mean it!) That said, we also enjoy dishware and
                  shiny new four-slice toasters too, so if you really would like
                  to get us something, you can find some suggestions here:
                </p>
              </div>
              <div class='links'>
                <div>
                  <a
                    class='ir heading-amazon'
                    href='http://www.amazon.com/registry/wedding/30589SRQZH0Z5'
                    target='_blank'
                  >
                    Amazon
                  </a>
                </div>
                <div>
                  <a
                    href='https://www.honeyfund.com/wedding/carr-harrell-10-01-2022'
                    target='_blank'
                  >
                    Honey Fund
                  </a>
                </div>
                {/* <div>
                    <a
                      class='ir heading-crate-and-barrel'
                      href='http://www.crateandbarrel.com/Gift-Registry/Judie-Le-and-Z-Henry-frazer/r4975512'
                      target='_blank'
                    >
                      Crate &amp; Barrel
                    </a>
                  </div> */}
              </div>
              <p>
                Please send any gifts to our home in Los Angeles, as we will not
                be able to bring them with us from San Diego.
              </p>
            </div>
          </section>
        </center>
      </div>

      <Footer />
    </div>
  );
};

export default HomeScreen;
